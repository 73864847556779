import {
  Query,
  OrganisationQueryVariables,
  OrganisationBillingCheckoutUrlArgs,
  BillingIntervalEnum,
} from "../generated/graphql";
import gql from "graphql-tag";

import useApi from "../hooks/useApi";

const Query_organisationBillingCheckoutUrl = gql`
  query organisationBillingCheckoutUrl($id: ID!, $interval: BillingIntervalEnum, $returnUrl: AWSURL) {
    organisation(id: $id) {
      billing {
        checkoutUrl(interval: $interval, returnUrl: $returnUrl)
      }
    }
  }
`;

const useData = () => {
  const get = useApi<OrganisationQueryVariables & OrganisationBillingCheckoutUrlArgs, Query, string>(
    Query_organisationBillingCheckoutUrl,
    (data) => data.organisation.billing?.checkoutUrl
  );

  return {
    get,
  };
};

export {
  BillingIntervalEnum,
};
export default useData;
