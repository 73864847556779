import React from "react";
import classNames from "classnames";

const FormGroup = ({
  className,
  ...props
}: React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>) => <div className={classNames("form-group", className)} {...props} />;

export default FormGroup;
