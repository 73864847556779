import { useQuery } from "react-query";

import organisations from "../apis/organisations";

const useOrganisation = ({ id }: { id: string }) => {
  const api = organisations();

  const { data, isFetching } =
    useQuery(
      ["organisation", id],
      () => api.get({ id: id }),
      {
        cacheTime: 0,
      }
    );

  return {
    data: data,
    loading: isFetching,
  };
};

export default useOrganisation;
