import React from "react";
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from "../_PolymorphicType";

import Context from "./Context";

type FormItemProps<C extends React.ElementType> =
  PolymorphicComponentPropWithRef<
    C,
    {
      id: string;
      label?: React.ReactNode;
    }
  >;

type FormItemType = <C extends React.ElementType = "input">(
  props: FormItemProps<C>
) => React.ReactElement | null;

const FormItem: FormItemType = React.forwardRef(
  <C extends React.ElementType = "input">(
    { as, id, label, readOnly, ...props }: FormItemProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || "input";

    return (
      <Context.Consumer>
        {({
          loading,
          form: {
            register,
            formState: { errors },
          },
        }) => (
          <>
            <label htmlFor={id}>{!!label ? label : id}</label>
            <Component
              id={id}
              {...props}
              readOnly={loading || readOnly}
              {...register(id)}
            />
            {errors[id] && (
              <div
                className="terminal-alert terminal-alert-error"
                style={{ overflowWrap: "anywhere" }}
              >
                {errors[id]?.message?.toString() || "your input is invalid"}
              </div>
            )}
          </>
        )}
      </Context.Consumer>
    );
  }
);

export default FormItem;
