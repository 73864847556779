const TryNew = () => {
  window.location.replace("https://v2.admin.localhost.run");
return (
  <section>
    redirecting to <a href="https://v2.admin.localhost.run">https://v2.admin.localhost.run</a>
  </section>
);
};

export default TryNew;
