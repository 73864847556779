import { useEffect } from "react";
import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";

import { PlsHold } from "components";

const Social = () => {
  useEffect(() => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  }, []);

  return <PlsHold message="logging in with google" />;
};

export default Social;
