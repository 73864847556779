import {
  Routes,
  Route,
  Navigate,
  HashRouter as BrowserRouter,
  useLocation,
  Outlet,
  useParams,
  useSearchParams,
  useHref,
} from "react-router-dom";

import { Page, Header as OriginalHeader, Link, Collection } from "components";

import {
  KeyCollectionItem,
  KeyItem,
  PlsHold,
  DomainItem,
} from "./components";

import * as NewOrganisationsItem from "components/Organisations/Item/Item";

import type { Organisation as OrganisationType } from "apis/organisations";
import type { Domain as DomainType } from "apis/domains";
import { OrganisationCollectionItem } from "components/Organisations";

import { DomainCollectionItem } from "components/Domains";

import useOrganisationCollection from "hooks/useOrganisationCollection";
import useDomainCollection from "hooks/useDomainCollection";
import useKeyCollection from "hooks/useKeyCollection";

import Home from "./routes/Home";

import Login from "./routes/Login";
import Logout from "./routes/Logout";
import useAuth from "./hooks/useAuth";

import React, { Suspense, useEffect } from "react";
import Help from "routes/Help";
import TryNew from "routes/TryNew";

import useOrganisationCheckoutUrl, {
  BillingIntervalEnum,
} from "hooks/useOrganisationCheckoutUrl";

import useOrganisationBillingUrl from "hooks/useOrganisationBillingUrl";
import useOrganisation from "hooks/useOrganisation";
import useDomain from "hooks/useDomain";

interface Ibutton {
  url: string;
  name: string;
}

const LinkButton = (props: Ibutton) => {
  const location = useLocation();
  return (
    <Link to={props.url} state={{ from: location }}>
      <button className="btn btn-primary">{props.name}</button>
    </Link>
  );
};

const LeftHeader = ({ name }: { name: string }) => (
  <h1 style={{ flex: 1 }}>{name}</h1>
);

const Header = ({
  left,
  ...props
}: Omit<Parameters<typeof OriginalHeader>[0], "left"> & { left: string }) => (
  <OriginalHeader left={<LeftHeader name={left} />} {...props} />
);

const RoutedPage = () => {
  let [searchParams] = useSearchParams();
  const orgId = searchParams.get("orgId") || undefined;
  return (
    <RequireAuth>
      <Page orgId={orgId}>
        <Suspense fallback={<PlsHold />}>
          <Outlet />
        </Suspense>
      </Page>
    </RequireAuth>
  );
};

const OrganisationCollectionWithRedirect = () => {
  const collection = useOrganisationCollection();
  const firstOrg = collection.data[0];

  return collection.data.length === 1 ? (
    <Navigate to={firstOrg.id} />
  ) : (
    <Collection
      renderItem={(props) => (
        <Link to={props.id}>
          <OrganisationCollectionItem {...props} />
        </Link>
      )}
      {...collection}
    />
  );
};

const DomainCollection = ({ organisationId }: { organisationId: string }) => {
  const organisationData = useOrganisation({id: organisationId});
  const collection = useDomainCollection(organisationId);

  const organisation = organisationData.data as OrganisationType;

  return !organisation.billing?.active ? (
    <Link to="../" relative="path">get started by choosing a subscription</Link>
  ) : (
    <Collection
      renderItem={(props) => (
        <Link to={props.id}>
          <DomainCollectionItem {...props} />
        </Link>
      )}
      {...collection}
    />
  );
};

const KeyCollection = () => {
  const collection = useKeyCollection();

  return collection.data.length < 1 ? (
    <section>
      <p>
        you need to add a ssh key to use custom domain plans, please add one now
      </p>
      <a
        href="https://localhost.run/docs/faq#generating-an-ssh-key"
        target="lhrhelp"
      >
        click here for more information
      </a>
    </section>
  ) : (
    <Collection
      renderItem={(props) => (
        <Link to={props.id}>
          <KeyCollectionItem {...props} />
        </Link>
      )}
      {...collection}
    />
  );
};

const Checkout = ({
  organisationId,
  interval,
}: {
  organisationId: string;
  interval: BillingIntervalEnum;
}) => {
  const returnUrl = new URL(
    useHref("../../", {relative: "path"}),
    window.location.origin
  ).toString();
  const urlResponse = useOrganisationCheckoutUrl({
    organisationId,
    interval,
    returnUrl,
  });
  const url = urlResponse.data as string;
  window.location.replace(url);
  return (
    <>
      <div>redirecting to <a href={url}>{url}</a></div>
    </>
  );
};

const Billing = ({
  organisationId,
}: {
  organisationId: string;
}) => {
  const returnUrl = new URL(
    useHref("../", {relative: "path"}),
    window.location.origin
  ).toString();
  const urlResponse = useOrganisationBillingUrl({
    organisationId,
    returnUrl,
  });
  const url = urlResponse.data as string;
  window.location.replace(url);
  return (
    <>
      <div>redirecting to <a href={url}>{url}</a></div>
    </>
  );
};


const DefinedPage = ({
  header,
  body,
}: {
  header: React.ReactNode;
  body: React.ReactNode;
}) => (
  <>
    {header}
    {body}
  </>
);

const WithParams = <T extends {}>({
  Component,
}: {
  Component: React.ComponentType<T>;
}) => {
  const params = useParams() as T;
  return <Component {...params} />;
};

interface pageComponents {
  header: React.ReactNode;
  body: React.ReactNode;
}

const CollectionHeader = ({
  title,
  right,
}: {
  title: string;
  right?: React.ReactNode | React.ReactNode[];
}) => {
  const realisedRight = [
    ...(Array.isArray(right) ? right : [right]),
    <LinkButton url="new" name="new" />,
  ];
  return <Header left={title} right={realisedRight} />;
};

const TitleHeader = ({
  title,
  right = [],
}: {
  title: string;
  right?: React.ReactNode | React.ReactNode[];
}) => {
  const realisedRight = [
    ...(Array.isArray(right) ? right : [right]),
  ];
  return <Header left={title} right={realisedRight} />;
};

const ElementRoute = ({
  collection,
  item,
}: {
  collection: pageComponents;
  item: pageComponents;
}) => (
  <Routes>
    <Route index element={<DefinedPage {...collection} />} />
    <Route path="new" element={<DefinedPage {...item} />} />
    <Route path=":id" element={<DefinedPage {...item} />} />
  </Routes>
);

const Key = {
  collection: {
    header: <CollectionHeader title="SSH Keys" />,
    body: <KeyCollection />,
  },
  item: {
    header: <TitleHeader title="SSH Key" />,
    body: <WithParams Component={KeyItem} />,
  },
};

const useEnsureSearchParam = ({name, value}: {name: string, value?: string}) => {

  const [, setSearchParams] = useSearchParams();
  useEffect(
    () => {
      setSearchParams((prevSearchParams) => {
        const filteredSearchParams = Array.from(prevSearchParams).filter(([k, v]) => k !== name);
        return !value ? filteredSearchParams : [[name, value], ...filteredSearchParams];
      });
      return
    },
    [name, value, setSearchParams]
  );
  // const navigate = useNavigate();
  // const location = useLocation();
  // const search = new URLSearchParams(
  //   !!value ? {[name]: value} : {}
  // ).toString();
  // navigate({
  //   pathname: location.pathname,
  //   search,
  // }, {replace: true})

}

const CreateOrganisationDomainRoute = ({organisation}: {organisation: OrganisationType}) => {
  return (
    <DefinedPage header={<TitleHeader title={`Domains -> new`} />} body={<DomainItem organisation={organisation} />} />
  )
}
const ManageOrganisationDomainRoute = ({organisation, id}: {organisation: OrganisationType; id: string}) => {
  const domainData = useDomain({id});
  const domain = domainData.data as DomainType;
  return (
    <DefinedPage
        header={<TitleHeader title={`Domains -> ${domain.name}`} />}
        body={<DomainItem organisation={organisation} id={id}/>}
    />

  )
};

const OrganisationDomainsRoute = ({organisation}: {organisation: OrganisationType}) => {
  return (
    <Routes>
      <Route index element={<DefinedPage header={<CollectionHeader title="Domains" />} body={<DomainCollection organisationId={organisation.id} />}/>} />
      <Route
        path=":id"
        element={
          <WithParams Component={({id}: {id: string}) => <ManageOrganisationDomainRoute organisation={organisation} id={id} />}  />
        }
      />
      <Route path="new/*" element={<CreateOrganisationDomainRoute organisation={organisation} />} />
    </Routes>
  )
};

const ManageOrganisationRoute = ({id}: {id: string}) => {
  const organisationData = useOrganisation({id});
  const organisation = organisationData.data as OrganisationType;
  useEnsureSearchParam({name: "orgId", value: id});
  const alerts = [
    ...(organisation.billing.needsAttention ? ["Your billing needs attention"] : [])

  ];
  const Alert = () => (
    <>
      { alerts.length > 0 && <div className="terminal-alert terminal-alert-error">{alerts.map(alert => <div>{alert}</div>)}</div> }
      <Outlet />
    </>
  )
  return (
    <Routes>
      <Route element={<Alert/>}>
        <Route
          index
          element={
            <DefinedPage
              header={<TitleHeader title={`Organisations -> ${organisation.name}`} />}
              body={<NewOrganisationsItem.Edit {...organisation} />}
            />
          }
        />
        <Route path="checkout">
          <Route path="month" element={<Checkout organisationId={id} interval={"month" as BillingIntervalEnum} />} />
          <Route path="year" element={<Checkout organisationId={id} interval={"year" as BillingIntervalEnum} />} />
        </Route>
        <Route
          path="billing"
          element={
            <Billing organisationId={id}/>
          }
        />
        <Route path="domains/*" element={<OrganisationDomainsRoute organisation={organisation} />} />
      </Route>
    </Routes>
  )
}

const CreateOrganisationRoute = () => {
  useEnsureSearchParam({name: "orgId"});
  return (
    <DefinedPage header={<TitleHeader title={`Organisations -> new`} />} body={<NewOrganisationsItem.Create />} />
  )
}

const OrganisationsRoute = () => {
  return (
    <Routes>
      <Route index element={<DefinedPage header={<CollectionHeader title="Organisations" />} body={<OrganisationCollectionWithRedirect />}/>} />
      <Route path=":id/*" element={<WithParams Component={ManageOrganisationRoute} />} />
      <Route path="new/*" element={<WithParams Component={CreateOrganisationRoute} />} />
    </Routes>
  )
}

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<RoutedPage />}>
          <Route index element={<Home />} />

          <Route path="/help" element={<Help />} />
          <Route path="/trynew" element={<TryNew />} />

          <Route path="organisations/*" element={<OrganisationsRoute />} />

          <Route path="keys">
            <Route index path="*" element={<ElementRoute {...Key} />} />
          </Route>
      </Route>
      <Route path="/login/*" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  </BrowserRouter>
);

function RequireAuth({ children }: { children: JSX.Element }) {
  let username = useAuth().username;
  let location = useLocation();

  if (!username) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export default App;
