import { useInfiniteQuery } from "react-query";

import useKeyData from "../apis/keys";

const useKeyCollection = ({ count = 10 }: { count?: number } = {}) => {
  const api = useKeyData();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      ["keys"],
      ({ pageParam }) => api.collection({ first: count, ...pageParam }),
      {
        getNextPageParam: (lastPage) =>
          lastPage.pageInfo?.hasNextPage && {
            after: lastPage.pageInfo.endCursor,
          },
      }
    );

  const flatData = data?.pages
    .flatMap((page) => page.edges)
    .map((edge) => edge.node);

  return {
    data: flatData || [],
    loading: isFetchingNextPage,
    nextPageFn: hasNextPage ? fetchNextPage : undefined,
  };
};

export default useKeyCollection;
