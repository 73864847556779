import useKeyCollection from "hooks/useKeyCollection";
import { Navigate } from "react-router-dom";

export default function Root() {
  const { data: keyData } = useKeyCollection({ count: 2 });
  return keyData.length < 1 ? (
    <Navigate to="/keys" />
  ) : (
    <Navigate to="/organisations" />
  );
}
