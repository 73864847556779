import { Organisation } from "../../generated/graphql";
import classNames from "classnames";

const CollectionItem = (props: Organisation) => {
  const needsAttention = props.billing.needsAttention;
  return (
    <>
      <div
        style={{ backgroundColor: "inherit" }}
        className={classNames("terminal-card", {
          "terminal-alert-error": needsAttention,
        })}
      >
        {needsAttention && <header>billing needs attention</header>}
        <div style={{ backgroundColor: "inherit" }}>
          {props.name} ({props.id.split("-", 1)})
        </div>
      </div>
      <br />
    </>
  );
}

export default CollectionItem;
