import {
  Query,
  Mutation,
  OrganisationsQueryVariables,
  OrganisationQueryVariables,
  OrganisationBillingBillingPortalSessionUrlArgs,
  Organisation,
  UserOrganisationConnection,
  CreateOrganisationMutationVariables,
  DeleteOrganisationMutationVariables,
  DeleteOrganisationPayload,
  
} from "../generated/graphql";
import gql from "graphql-tag";

import useApi from "../hooks/useApi";

const Query_organisations = gql`
  query organisations($after: String, $first: Int!) {
    user {
      id
      email
      organisations(after: $after, first: $first) {
        edges {
          node {
            id
            name
            billing {
              active
              needsAttention
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const Query_organisation = gql`
  query organisation($id: ID!) {
    organisation(id: $id) {
      id
      name
      billing {
        active
        needsAttention
        plans {
          title
          features
          intervals {
            interval
            price
            active
          }
        }
      }
    }
  }
`;

const Mutation_createOrganisation = gql`
  mutation createOrganisation($input: CreateOrganisationInput!) {
    createOrganisation(input: $input) {
      organisation {
        id
      }
    }
  }
`;

const Mutation_deleteOrganisation = gql`
  mutation deleteOrganisation($input: DeleteOrganisationInput!) {
    deleteOrganisation(input: $input) {
      organisationId
    }
  }
`;

const useData = () => {
  const collection = useApi<
    OrganisationsQueryVariables,
    Query,
    UserOrganisationConnection
  >(Query_organisations, (data: Query) => data.user.organisations);

  const get = useApi<OrganisationQueryVariables & OrganisationBillingBillingPortalSessionUrlArgs, Query, Organisation>(
    Query_organisation,
    (data) => data.organisation
  );

  const create = useApi<
    CreateOrganisationMutationVariables,
    Mutation,
    Organisation
  >(Mutation_createOrganisation, (data) => data.createOrganisation.organisation);


  const remove = useApi<DeleteOrganisationMutationVariables, Mutation, DeleteOrganisationPayload>(Mutation_deleteOrganisation, (data) => data.deleteOrganisation);

  return {
    collection,
    get,
    create,
    remove,
  };
};

export type {
  Organisation,
};

export default useData;
