const Help = () => (
  <section>
    <h1>need help?</h1>
    <section>
    <a href="https://localhost.run/docs/" target="lhrhelp">
      read the docs at https://localhost.run/docs/
    </a>
    </section>
    <section>
    <a href="mailto:help@localhost.run" target="lhrhelp">
      email help@localhost.run
    </a>
    </section>
  </section>
);

export default Help;
