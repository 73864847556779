import React, { JSXElementConstructor } from "react";
import Button from "./Button";

interface IItem {
  id: string;
}

interface CollectionProps<T> extends React.HTMLAttributes<HTMLOrSVGElement> {
  renderItem: JSXElementConstructor<T>;
  data: T[];
  nextPageFn?: () => void;
  loading: boolean;
}

const Collection: <T extends IItem>(
  p: CollectionProps<T>
) => React.ReactElement<CollectionProps<T>> = ({
  renderItem: RenderItem,
  data,
  nextPageFn,
  loading,
}) => {
  return (
    <section>
      <section>
        {data?.map((item) => (
          <RenderItem key={item.id} {...item} />
        ))}
      </section>
      <br />
      <section>
        <Button
          primary
          className="btn-block"
          onClick={() => !!nextPageFn && nextPageFn()}
          // disabled={true}
          disabled={loading || !nextPageFn}
        >
          {loading
            ? "Loading more..."
            : !!nextPageFn
            ? "Load more"
            : "Nothing more to load"}
        </Button>
      </section>
    </section>
  );
};

export default Collection;
