import { DocumentNode } from "graphql";
import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const useApi = <Variables extends {}, RawQueryT = unknown, T = unknown>(
  query: DocumentNode,
  pathFn: (data: RawQueryT) => T
) => {
  const apiFn = async (props: Variables) => {
    const { data, errors } = (await API.graphql(
      graphqlOperation(query, props)
    )) as GraphQLResult<RawQueryT>;
    // await sleep(2000);
    if (errors) throw new Error(errors[0].message);
    if (!data)
      throw new Error(
        "i didn't get an error, but I am missing data, which confuses me"
      );
    return pathFn(data);
  };
  return apiFn;
};

export default useApi;
