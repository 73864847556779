import { useQuery } from "react-query";

import organisationBillingBillingUrl from "../apis/organisationBillingBillingUrl";

const useOrganisationBillingBillingUrl = ({ organisationId, returnUrl }: { organisationId: string, returnUrl?: string }) => {
  const api = organisationBillingBillingUrl();

  const { data, isFetching } =
    useQuery(
      ["organisation", organisationId, "billing", "billingUrl"],
      () => api.get({ id: organisationId, returnUrl }),
      {
        cacheTime: 0,
      }
    );

  return {
    data: data,
    loading: isFetching,
  };
};

export default useOrganisationBillingBillingUrl;
