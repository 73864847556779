import { useEffect, FC } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Auth } from "aws-amplify";

interface LocationState {
  from?: {
    pathname?: string;
  };
}

const Logout: FC = () => {
  const location = useLocation();
  const from = (location.state as LocationState)?.from?.pathname || "/";

  useEffect(() => {
    Auth.signOut();
  }, []);

  return <Navigate to={from} replace />;
};

export default Logout;
