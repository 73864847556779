import {
  Query,
  OrganisationQueryVariables,
  OrganisationBillingBillingUrlArgs,
} from "../generated/graphql";
import gql from "graphql-tag";

import useApi from "../hooks/useApi";

const Query_organisationBillingBillingUrl = gql`
  query organisationBillingBillingUrl($id: ID!, $returnUrl: AWSURL) {
    organisation(id: $id) {
      billing {
        billingPortalSessionUrl(returnUrl: $returnUrl)
      }
    }
  }
`;

const useData = () => {
  const get = useApi<OrganisationQueryVariables & OrganisationBillingBillingUrlArgs, Query, string>(
    Query_organisationBillingBillingUrl,
    (data) => data.organisation.billing?.billingPortalSessionUrl
  );

  return {
    get,
  };
};

export default useData;
