import {
  Query,
  Mutation,
  SshKeyPairQueryVariables,
  CreateSshKeyPairMutationVariables,
  DeleteSshKeyPairMutationVariables,
  SshKeyPairsQueryVariables,
  DeleteSshKeyPairPayload,
  SshKeyPair,
  UserSshKeyPairConnection,
} from "../generated/graphql";
import gql from "graphql-tag";

import useApi from "../hooks/useApi";

const Query_sshKeyPairs = gql`
  query sshKeyPairs($after: String, $first: Int!) {
    user {
      id
      email
      sshKeyPairs(after: $after, first: $first) {
        edges {
          node {
            id
            publicKey
            fingerprint
            description
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const Query_sshKeyPair = gql`
  query sshKeyPair($id: ID!) {
    sshKeyPair(id: $id) {
      id
      publicKey
      fingerprint
      description
    }
  }
`;

const Mutation_createSshKeyPair = gql`
  mutation createSshKeyPair($input: CreateSshKeyPairInput!) {
    createSshKeyPair(input: $input) {
      sshKeyPair {
        id
      }
    }
  }
`;

const Mutation_deleteSshKeyPair = gql`
  mutation deleteSshKeyPair($input: DeleteSshKeyPairInput!) {
    deleteSshKeyPair(input: $input) {
      sshKeyPairId
    }
  }
`;

const useData = () => {
  const collection = useApi<
    SshKeyPairsQueryVariables,
    Query,
    UserSshKeyPairConnection
  >(Query_sshKeyPairs, (data: Query) => data.user.sshKeyPairs);

  const get = useApi<SshKeyPairQueryVariables, Query, SshKeyPair>(
    Query_sshKeyPair,
    (data) => data.sshKeyPair
  );

  const create = useApi<
    CreateSshKeyPairMutationVariables,
    Mutation,
    SshKeyPair
  >(Mutation_createSshKeyPair, (data) => data.createSshKeyPair.sshKeyPair);

  const remove = useApi<
    DeleteSshKeyPairMutationVariables,
    Mutation,
    DeleteSshKeyPairPayload
  >(Mutation_deleteSshKeyPair, (data) => data.deleteSshKeyPair);

  return {
    collection,
    get,
    create,
    remove,
  };
};

export default useData;
