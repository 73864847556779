import {
  Query,
  Mutation,
  DomainsQueryVariables,
  DomainQueryVariables,
  DomainConnection,
  Domain,
  CreateDomainMutationVariables,
  DeleteDomainMutationVariables,
  DeleteDomainPayload,
} from "../generated/graphql";
import gql from "graphql-tag";

import useApi from "../hooks/useApi";

const Query_organisation_domains = gql`
  query domains($orgId: ID!, $after: String, $first: Int!) {
    organisation(id: $orgId) {
      domains(after: $after, first: $first) {
        total
        edges {
          node {
            id
            name
            dns {
              txt {
                active
              }
              alias {
                active
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

const Query_domain = gql`
  query domain($id: ID!) {
    domain(id: $id) {
      id
      name
      dns {
        txt {
          active
          target
        }
        alias {
          active
          target
        }
      }
    }
  }
`;

const Mutation_createDomain = gql`
  mutation createDomain($input: CreateDomainInput!) {
    createDomain(input: $input) {
      domain {
        id
      }
    }
  }
`;

const Mutation_deleteDomain = gql`
  mutation deleteDomain($input: DeleteDomainInput!) {
    deleteDomain(input: $input) {
      domainId
    }
  }
`;

const useData = () => {
  const collection = useApi<DomainsQueryVariables, Query, DomainConnection>(
    Query_organisation_domains,
    (data: Query) => data.organisation.domains
  );

  const get = useApi<DomainQueryVariables, Query, Domain>(
    Query_domain,
    (data) => data.domain
  );

  const create = useApi<CreateDomainMutationVariables, Mutation, Domain>(
    Mutation_createDomain,
    (data) => data.createDomain.domain
  );

  const remove = useApi<
    DeleteDomainMutationVariables,
    Mutation,
    DeleteDomainPayload
  >(Mutation_deleteDomain, (data) => data.deleteDomain);

  return {
    collection,
    get,
    create,
    remove,
  };
};

export type {
  Domain,
}

export default useData;
