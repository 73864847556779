import React from "react";
import { UseFormReturn } from "react-hook-form";

import Context from "./Context";

const Form = ({
  loading,
  form,
  ...props
}: { loading: boolean; form: UseFormReturn } & React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>) => (
  <Context.Provider value={{ form, loading }}>
    <form {...props} />
  </Context.Provider>
);

export default Form;
