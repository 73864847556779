import { NavLink, Outlet } from "react-router-dom";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import classNames from "classnames";

import Email from "./Email";
import Social from "./Social";

import useAuth from "../../hooks/useAuth";

const LoginRoute = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="" element={<Login />}>
        <Route
          index
          element={<Navigate to="email" replace state={location.state} />}
        />
        <Route path="email" element={<Email />} />
        <Route path="social" element={<Social />} />
      </Route>
    </Routes>
  );
};

interface LocationState {
  from?: {
    pathname?: string;
  };
}

const Login = () => {
  const location = useLocation();
  const from = (location.state as LocationState)?.from?.pathname || "/";
  const username = useAuth().username;

  return !!username ? (
    <Navigate to={from} replace />
  ) : (
    <>
      <div className="terminal-nav">
        <div className="terminal-logo">
          <div className="logo terminal-prompt">lhr</div>
        </div>
        <nav className="terminal-menu">
          <ul>
            {[
              {
                url: "email",
                name: "login with email",
              },
              {
                url: "social",
                name: "login with google",
              },
            ].map(({ url, name }) => (
              <li key={name}>
                <NavLink
                  to={url}
                  className={({ isActive }) =>
                    classNames("menu-item", { active: isActive })
                  }
                >
                  {name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <section>
        <Outlet />
      </section>
    </>
  );
};

export default LoginRoute;
