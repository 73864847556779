import { useQuery } from "react-query";

import organisationBillingCheckoutUrl, { BillingIntervalEnum } from "../apis/organisationBillingCheckoutUrl";

const useOrganisationBillingCheckoutUrl = ({ organisationId, interval, returnUrl }: { organisationId: string, interval: BillingIntervalEnum, returnUrl?: string }) => {
  const api = organisationBillingCheckoutUrl();

  const { data, isFetching } =
    useQuery(
      ["organisation", organisationId, "billing", "checkoutUrl"],
      () => api.get({ id: organisationId, interval, returnUrl }),
      {
        cacheTime: 0,
      }
    );

  return {
    data: data,
    loading: isFetching,
  };
};

export {
  BillingIntervalEnum,
}
export default useOrganisationBillingCheckoutUrl;
