import React, { Suspense } from "react";
// import { Component, ErrorInfo, ReactNode } from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import { PlsHold } from "components";
import Config from "providers/Config";
import Auth from "providers/Auth";
import Stripe from "providers/Stripe";
import Sentry from "providers/Sentry";
import { QueryClient, QueryClientProvider } from "react-query";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
});

// interface Props {
//   children?: ReactNode;
// }

// interface State {
//   hasError: boolean;
//   error?: Error;
// }

// class ErrorBoundary extends Component<Props, State> {
//   public state: State = {
//     hasError: false,
//   };

//   public static getDerivedStateFromError(error: Error): State {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true, error: error };
//   }

//   public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
//     console.error("Uncaught error:", error, errorInfo);
//   }

//   public render() {
//     return this.state.hasError ? (
//       <div>
//         <h2>Something went wrong :(</h2>
//         <p>
//           <a href="/">try a reload</a>
//         </p>
//         {this.state.error && <p>{this.state.error.toString()}</p>}
//       </div>
//     ) : (
//       this.props.children
//     );
//   }
// }

root.render(
  <div className="container">
    {/* <ErrorBoundary> */}
    <Config>
      <Sentry>
        <QueryClientProvider client={queryClient}>
          <Auth>
            <React.StrictMode>
              <Suspense fallback={<PlsHold message="loading data" />}>
                <Stripe>
                  <App />
                </Stripe>
              </Suspense>
            </React.StrictMode>
          </Auth>
        </QueryClientProvider>
      </Sentry>
    </Config>
    {/* </ErrorBoundary> */}
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
