import { useForm } from "react-hook-form";
import { GraphQLResult } from "@aws-amplify/api";

import { Domain, Organisation } from "../../generated/graphql";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useData from "../../apis/domains";
import { useNavigate } from "react-router-dom";

import { Form, FormItem, FormGroup, FormConfirmButton } from "../Form";
import ButtonGroup from "../ButtonGroup";

const Edit = ({
  organisation,
  id,
}: {
  organisation: Organisation;
  id?: string;
}) =>
  !!id ? (
    <EditWithData organisation={organisation} id={id} />
  ) : (
    <EditForm organisation={organisation} />
  );

const EditWithData = ({
  organisation,
  id,
}: {
  organisation: Organisation;
  id: string;
}) => {
  const api = useData();
  const { data } = useQuery(["domains", "items", id], () => api.get({ id }));

  return <EditForm organisation={organisation} item={data} />;
};

const EditForm = ({
  organisation,
  item,
}: {
  organisation: Organisation;
  item?: Omit<Domain, "id"> & { id?: string };
}) => {
  const organisationId = organisation.id;
  const navigate = useNavigate();
  const api = useData();
  const queryClient = useQueryClient();
  // const createMutation = useMutation(api.create, {onSuccess: (data) => {queryClient.removeQueries(["domains"]); navigate(data.id, {replace: true})}});

  const form = useForm();
  const { handleSubmit, setError } = form;

  const createMutation = useMutation(api.create, {
    onSuccess: (domain) => {
      queryClient.removeQueries(["domains"]);
      navigate(`../${domain.id}`, {"relative": "path"});
    },
    onError: (error: GraphQLResult) =>
      setError("name", {
        type: "server",
        message: error.errors?.[0].message,
      }),
  });
  const removeMutation = useMutation(api.remove, {
    onSuccess: () => {
      queryClient.removeQueries(["domains"]);
      navigate(`../`, {"relative": "path"});
    },
    onError: (error: GraphQLResult) =>
      setError("name", {
        type: "server",
        message: error.errors?.[0].message,
      }),
  });

  const loading = createMutation.isLoading || removeMutation.isLoading;

  const internalOnSubmit = handleSubmit(({ name }) => {
    createMutation.mutate({ input: { name, organisationId } });
  });

  const planInterval = organisation.billing.plans[0].intervals.find(i => i.active);
  const planPrice = planInterval?.interval == "year" ? planInterval.price / 12 : planInterval?.interval == "month" ? planInterval?.price : NaN;

  return (
    <Form loading={loading} form={form} onSubmit={internalOnSubmit}>
      <fieldset>
        <FormGroup hidden={!item?.id}>
          {!item?.dns.alias?.active && (
            <div className="terminal-alert terminal-alert-error">
              <p>
                to route tunnels for this domain you need to create either a
                CNAME or A records
              </p>
              <p>
                <a
                  href="https://localhost.run/docs/custom-domains/#setup-dns-for-your-own-domain"
                  target="lhrhelp"
                >
                  click here for more information
                </a>
              </p>
            </div>
          )}
          {!item?.dns.txt?.active && (
            <div className="terminal-alert terminal-alert-error">
              <p>
                add the TXT record _lhr.{item?.name} TXT "{item?.id}" to your
                DNS service to verify your domain
              </p>
              <p>
                <a
                  href="https://localhost.run/docs/custom-domains/#setup-dns-for-your-own-domain"
                  target="lhrhelp"
                >
                  click here for more information
                </a>
              </p>
            </div>
          )}

          <FormItem
            id="id"
            readOnly
            label="ID"
            required
            disabled
            defaultValue={item?.id}
            type="text"
          />
        </FormGroup>

        <FormGroup>
          <FormItem
            id="name"
            readOnly={!!item?.id}
            required
            type="text"
            autoFocus
            defaultValue={item?.name}
            placeholder="name"
          />
        </FormGroup>

        <ButtonGroup>
          {!item?.id && (
            <FormConfirmButton
              primary
              type="submit"
              name="submit"
              id="submit"
              hidden={!!item?.id}
              disabled={!!item?.id}
            >
              add domain for ${planPrice / 100} per month
            </FormConfirmButton>
          )}
          {!!item?.id && (
            <FormConfirmButton
              type="button"
              name="delete"
              onClick={() =>
                !!item?.id && removeMutation.mutate({ input: { id: item.id } })
              }
            >
              delete
            </FormConfirmButton>
          )}
        </ButtonGroup>
      </fieldset>
    </Form>
  );
};

export default Edit;
