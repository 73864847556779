import React from "react";
import useConfig from "../hooks/useConfig";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

type Props = {
  children?: React.ReactNode;
};

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const Stripe = (props: Props) => {
  const config = useConfig();

  const stripePromise = loadStripe(config.stripe.publishable_key);

  return <Elements stripe={stripePromise}>{props.children}</Elements>;
};

export default Stripe;
