import React, { useState, useEffect, createContext, useContext } from "react";
import { PlsHold } from "../components";

interface Ctx {
  Amplify: {};
  stripe: {
    publishable_key: string;
  };
  sentry: {
    dsn: string;
    environment: string;
  };
}

const ConfigContext = createContext<Ctx>({
  Amplify: {},
  stripe: { publishable_key: "" },
  sentry: { dsn: "", environment: "local" },
});

export const _useConfig = () => useContext(ConfigContext);

type Props = {
  children?: React.ReactNode;
};

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const Config = (props: Props) => {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    fetch("/config.json", { cache: "no-cache" })
      .then((response) => response.json())
      // .then((d) => sleep(500).then(() => d))
      .then((new_config) => {
        setConfig(new_config);
      });
  }, []);
  if (config == null) return <PlsHold message="wait for config" />;
  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext };
export default Config;
