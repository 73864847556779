import { ErrorBoundary, init } from "@sentry/react";
import { useEffect, ReactNode } from "react";
import useConfig from "../hooks/useConfig";
import { FallbackRender } from "@sentry/react/types/errorboundary";

const ErrorPage: FallbackRender = ({ error, componentStack }) => (
  <div>
    <h2>Something went wrong :(</h2>
    <div>
      <a href="/">try a reload</a>
    </div>
    <div>{error.toString()}</div>
    <div>{componentStack}</div>
  </div>
);

const Sentry = ({ children }: { children: ReactNode }) => {
  const config = useConfig();
  const sentryConfig = config.sentry;
  useEffect(() => {
    init(sentryConfig);
  }, [sentryConfig]);
  return (
    <ErrorBoundary fallback={ErrorPage} showDialog>
      {children}
    </ErrorBoundary>
  );
};

export default Sentry;
