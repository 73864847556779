import { ReactNode } from "react";
import { useLocation, generatePath, Navigate } from "react-router-dom";
import NavLink from "./NavLink";
import classNames from "classnames";
import useOrganisationCollection from "../hooks/useOrganisationCollection";

const Page = ({
  orgId,
  children,
}: {
  orgId?: string;
  children: ReactNode;
}) => {
  const { data: organisations } = useOrganisationCollection();
  let location = useLocation();

  if (!orgId && organisations.length === 1) {
    return (
      <Navigate
        to={{
          pathname: location.pathname,
          search: new URLSearchParams({
            orgId: organisations[0].id,
          }).toString(),
        }}
        replace
      />
    );
  }

  return (
    <>
      <div className="terminal-nav StickyHeader">
        <div className="terminal-logo">
          <div className="logo terminal-prompt">
            <NavLink to={"/"} className="no-style">
              lhr
            </NavLink>
          </div>
        </div>
        <nav className="terminal-menu">
          <ul>
            {[
              ...(!!orgId
                ? [
                    {
                      url: generatePath("/organisations/:id/domains", {
                        id: orgId,
                      }),
                      name: "domains",
                    },
                    {
                      url: generatePath("/organisations/:id", {
                        id: orgId,
                      }),
                      name: "billing",
                    },
                  ]
                : []),
              ...(organisations.length > 1
                ? [
                    {
                      url: "/organisations",
                      name: "organisations",
                    },
                  ]
                : []),
              {
                url: "/keys",
                name: "SSH keys",
              },
            ].map(({ url, name }) => (
              <li key={name}>
                <NavLink
                  to={url}
                  className={({ isActive }) =>
                    classNames("menu-item", { active: isActive })
                  }
                >
                  {name}
                </NavLink>
              </li>
            ))}
            <li>
              <NavLink to="/trynew" state={{ from: location }}>
                try the new (retro) site
              </NavLink>
            </li>
            <li>
              <NavLink to="/help" state={{ from: location }}>
                help
              </NavLink>
            </li>
            <li>
              <NavLink to="/logout" state={{ from: location }} replace>
                logout {/* userData?.email */}
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
      <div className="StickyContent">{children}</div>
    </>
  );
};

export default Page;
