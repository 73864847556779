import React, { useState, useEffect, ReactNode, useMemo } from "react";
import Button from "./Button";

const ConfirmButton = ({
  onClick,
  children,
  ...props
}: React.ComponentProps<typeof Button>) => {
  const [stage, setStage] = useState<number>(2);
  const defaultState = useMemo(
    () => ({
      onClick: (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setStage(stage - 1);
      },
      children: (
        <>
          <span>click twice to&nbsp;</span>
          {children}
        </>
      ),
    }),
    [children, stage]
  );
  const [buttonState, setButtonState] =
    useState<{ children: ReactNode; onClick: any }>(defaultState);

  useEffect(() => {
    if (stage <= 1) {
      setButtonState({
        onClick: onClick,
        children: children,
      });
      const timeoutId = setTimeout(() => setStage(2), 5000);
      return () => clearTimeout(timeoutId);
    } else {
      setButtonState(defaultState);
    }
  }, [stage, children, onClick, defaultState]);

  return (
    <Button
      onClick={buttonState.onClick}
      children={buttonState.children}
      {...props}
      error={stage <= 1}
    />
  );
};

export default ConfirmButton;
