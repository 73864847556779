import React from "react";
import Button from "../Button";
import ConfirmButton from "../ConfirmButton";

import Context from "./Context";

const FormButton = ({ ...props }: React.ComponentProps<typeof Button>) => (
  <Context.Consumer>
    {({ loading }) => (
      <Button style={{ flex: 1 }} loading={loading} {...props} />
    )}
  </Context.Consumer>
);

const FormConfirmButton = ({
  ...props
}: React.ComponentProps<typeof ConfirmButton>) => (
  <Context.Consumer>
    {({ loading }) => (
      <ConfirmButton style={{ flex: 1 }} loading={loading} {...props} />
    )}
  </Context.Consumer>
);

export { FormConfirmButton, FormButton };
