import React from "react";
import { NavLink as RealNavLink, useSearchParams } from "react-router-dom";

const NavLink = ({
  to,
  style,
  ...props
}: Omit<React.ComponentProps<typeof RealNavLink>, "to"> & {
  to: string | { pathname: string; search?: Record<string, string> };
}) => {
  const [searchParams] = useSearchParams();

  if (typeof to === "string") {
    return (
      <RealNavLink
        to={{
          pathname: to,
          search: searchParams.toString(),
        }}
        style={{
          whiteSpace: "nowrap",
          ...style,
        }}
        {...props}
      />
    );
  } else {
    const combinedSearchParams = new URLSearchParams({
      ...searchParams,
      ...to.search,
    });
    return (
      <RealNavLink
        to={{
          pathname: to.pathname,
          search: combinedSearchParams.toString(),
        }}
        style={{
          whiteSpace: "nowrap",
          ...style,
        }}
        {...props}
      />
    );
  }
};

export default NavLink;
