import React from "react";
import { ReactNode } from "react";
import classNames from "classnames";
import {
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
} from "./_PolymorphicType";

type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  {
    className?: string;
    disabled?: boolean;
    primary?: boolean;
    error?: boolean;
    loading?: boolean;
    children: ReactNode;
  }
>;

type ButtonComponent = <C extends React.ElementType = "button">(
  props: ButtonProps<C>
) => React.ReactElement | null;

type DefaultButtonComponent = (
  props: ButtonProps<"button">
) => React.ReactElement | null;

const Button: DefaultButtonComponent & ButtonComponent = React.forwardRef(
  <C extends React.ElementType = "button">(
    {
      as,
      disabled = false,
      primary = false,
      error = false,
      loading = false,
      className,
      children,
      ...props
    }: ButtonProps<C>,
    ref?: PolymorphicRef<C>
  ) => {
    const Component = as || "button";
    const loadingOrDisabled = loading || disabled;
    return (
      <Component
        ref={ref}
        style={
          loadingOrDisabled ? { ...props.style, cursor: "initial" } : props.style
        }
        className={classNames(
          className,
          "btn",
          {
            "btn-ghost": loadingOrDisabled,
          },
          error ? "btn-error" : primary ? "btn-primary" : "btn-default"
        )}
        disabled={loadingOrDisabled}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

export default Button;
