import { SshKeyPair as sshKeyPairT } from "../../generated/graphql";

const CollectionItem = (props: sshKeyPairT) => (
  <>
    <div style={{ backgroundColor: "inherit" }} className="terminal-card">
      <header>{props.description}</header>
      <div style={{ backgroundColor: "inherit" }}>{props.fingerprint}</div>
    </div>
    <br />
  </>
);

export default CollectionItem;
