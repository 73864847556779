import { useQuery } from "react-query";

import domains from "../apis/domains";

const useDomain = ({ id }: { id: string }) => {
  const api = domains();

  const { data, isFetching } =
    useQuery(
      ["domains", id],
      () => api.get({ id: id }),
      {
        cacheTime: 0,
      }
    );

  return {
    data: data,
    loading: isFetching,
  };
};

export default useDomain;
