import { useInfiniteQuery } from "react-query";

import useDomainData from "../apis/domains";

const useDomainCollection = (
  orgId: string,
  { count = 10 }: { count?: number } = {}
) => {
  const api = useDomainData();

  const { data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery(
      ["domains", "collections", orgId],
      ({ pageParam }) => api.collection({ orgId, first: count, ...pageParam }),
      {
        getNextPageParam: (lastPage) =>
          lastPage.pageInfo?.hasNextPage && {
            after: lastPage.pageInfo.endCursor,
          },
      }
    );

  const flatData = data?.pages
    .flatMap((page) => page.edges)
    .map((edge) => edge.node);

  return {
    data: flatData || [],
    loading: isFetchingNextPage,
    nextPageFn: hasNextPage ? fetchNextPage : undefined,
  };
};

export default useDomainCollection;
