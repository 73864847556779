import { useForm } from "react-hook-form";
import { GraphQLResult } from "@aws-amplify/api";
import { GraphQLError } from "graphql";

import { SshKeyPair } from "../../generated/graphql";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useData from "../../apis/keys";
import { useNavigate, resolvePath, useLocation } from "react-router-dom";
import {
  Form,
  FormItem,
  FormGroup,
  FormButton,
  FormConfirmButton,
} from "../Form";
import ButtonGroup from "../ButtonGroup";

const Edit = ({ id }: { id?: string }) =>
  !!id ? <EditWithData id={id} /> : <EditForm />;

const EditWithData = ({ id }: { id: string }) => {
  const api = useData();

  const { data } = useQuery(["keys", id], () => api.get({ id }));

  return <EditForm item={data} />;
};

interface AppsyncGraphQLError extends GraphQLError {
  errorType?: String;
}

const EditForm = ({
  item,
}: {
  item?: Omit<SshKeyPair, "id"> & { id?: string };
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const api = useData();
  const queryClient = useQueryClient();
  const createMutation = useMutation(api.create, {
    onSuccess: (data) => {
      queryClient.removeQueries(["keys"]);
      navigate(resolvePath(`../${data.id}/edit`, location.pathname), {
        replace: true,
      });
    },
    onError: (error: GraphQLResult & { errors: AppsyncGraphQLError[] }) => {
      console.log("yolo", error);
      setError("publicKey", {
        type: "server",
        message:
          error.errors?.[0].errorType === "Duplicate"
            ? "This key is already in use on an account"
            : error.errors?.[0].message,
      });
    },
  });
  const removeMutation = useMutation(api.remove, {
    onSuccess: () => {
      queryClient.removeQueries(["keys"]);
      navigate(-1);
    },
    onError: (error: GraphQLResult) =>
      setError("publicKey", {
        type: "server",
        message: error.errors?.[0].message,
      }),
  });

  const loading = createMutation.isLoading || removeMutation.isLoading;

  const form = useForm();
  const { handleSubmit, setError } = form;

  const internalOnSubmit = handleSubmit(({ description, publicKey }) => {
    createMutation.mutate({ input: { description, publicKey } });
  });

  return (
    <Form loading={loading} form={form} onSubmit={internalOnSubmit}>
      <fieldset>
        <FormGroup hidden={!item?.id}>
          <FormItem
            id="id"
            readOnly
            label="ID"
            required
            disabled
            defaultValue={item?.id}
            type="text"
          />
        </FormGroup>

        <FormGroup>
          <FormItem
            id="description"
            readOnly={!!item?.id}
            required
            type="text"
            autoFocus
            defaultValue={item?.description!}
            placeholder="give your key a description"
          />
        </FormGroup>

        <FormGroup>
          <FormItem
            as="textarea"
            id="publicKey"
            readOnly={!!item?.id}
            label="SSH public key"
            placeholder="ssh-..."
            required
            defaultValue={item?.publicKey}
            rows={10}
          />
        </FormGroup>

        <ButtonGroup>
          {!item?.id && (
            <FormButton primary type="submit" name="submit" id="submit">
              submit
            </FormButton>
          )}
          {!!item?.id && (
            <FormConfirmButton
              type="button"
              name="delete"
              onClick={() =>
                !!item?.id && removeMutation.mutate({ input: { id: item.id } })
              }
            >
              delete
            </FormConfirmButton>
          )}
        </ButtonGroup>
      </fieldset>
    </Form>
  );
};

export default Edit;
