import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Auth } from "@aws-amplify/auth";

import { Form, FormItem, FormGroup, FormButton } from "components/Form";
import ButtonGroup from "components/ButtonGroup";

function getRandomString(bytes: number) {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join("");
}

function intToHex(nr: number) {
  return nr.toString(16).padStart(2, "0");
}

const Login = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm();
  const { handleSubmit, setFocus, reset: resetForm, setError, setValue } = form;
  const [cognitoUser, setCognitoUser] = useState(null);

  const reset = () => {
    resetForm();
    setCognitoUser(null);
  };

  const onSubmit = handleSubmit(({ email, otp, cognitoUser: _ }) => {
    setLoading(true);

    if (!!otp) {
      Auth.sendCustomChallengeAnswer(cognitoUser, otp)
        .then((result) => {
          if (!result.signInUserSession) {
            setLoading(false);
            setError("otp", {
              type: "manual",
              message: "the code is wrong, make sure you use the latest one",
            });
          }
        })
        .catch((error) => {
          setError("otp", { type: "manual", message: error.message });
        });
    } else {
      Auth.signIn(email)
        .then((data) => {
          setCognitoUser(data);
          setValue("otp", data?.challengeParam?.code);
          setLoading(false);
        })
        .catch((error) => {
          if (error.code === "UserNotFoundException") {
            Auth.signUp({
              username: email,
              password: getRandomString(30),
            })
              .then((result) => {
                Auth.signIn(email).then((data) => {
                  setCognitoUser(data);
                  setValue("otp", data?.challengeParam?.code);
                  setLoading(false);
                });
              })
              .catch((error) => {
                setError("email", { type: "manual", message: error.message });
              });
          } else {
            setLoading(false);
            setError("email", { type: "manual", message: error.message });
          }
        });
    }
  });

  useEffect(() => {
    if (!!cognitoUser) setFocus("otp");
  }, [setFocus, cognitoUser]);

  return (
    <Form loading={loading} form={form} onSubmit={onSubmit}>
      <fieldset>
        <legend>login with a magic code sent to your email address</legend>
        <FormGroup>
          <FormItem
            id="email"
            autoFocus={!cognitoUser}
            required
            readOnly={!!cognitoUser}
            type="email"
            placeholder="you@example.com"
          />
        </FormGroup>

        <FormGroup hidden={!cognitoUser}>
          <FormItem
            id="otp"
            autoFocus={!!cognitoUser}
            label="check your email for your one time password"
            required={!!cognitoUser}
            disabled={!cognitoUser}
            type="text"
            placeholder="magic code"
          />
        </FormGroup>
        <ButtonGroup>
          <FormButton primary type="submit" name="submit" id="submit">
            submit
          </FormButton>
          {!!cognitoUser && (
            <FormButton
              name="cancelEmail"
              id="cancelEmail"
              onClick={() => reset()}
            >
              cancel
            </FormButton>
          )}
        </ButtonGroup>
      </fieldset>
    </Form>
  );
};

export default Login;
