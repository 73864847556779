import { Domain } from "../../generated/graphql";
import classNames from "classnames";

const CollectionItem = (props: Domain) => (
  <>
    <div
      style={{ backgroundColor: "inherit" }}
      className={classNames("terminal-card", {
        "terminal-alert-error":
          !props.dns.alias?.active || !props.dns.txt?.active,
      })}
    >
      {(!props.dns.alias?.active || !props.dns.txt?.active) && (
        <header>DNS needs attention</header>
      )}
      <div style={{ backgroundColor: "inherit" }}>{props.name}</div>
    </div>
    <br />
  </>
);

export default CollectionItem;
