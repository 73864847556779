import React, { useState, useEffect, createContext } from "react";
import { PlsHold } from "../components";
import useConfig from "../hooks/useConfig";
import { useQueryClient } from "react-query";

// import { Authenticator as AmplifyAuthenticator } from "@aws-amplify/ui-react";
// import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth as AmplifyAuth, Amplify, Hub } from "aws-amplify";

type Props = {
  children?: React.ReactNode;
};

const AuthContext = createContext<{ username: string | undefined | null }>({
  username: undefined,
});

// const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface AmplifyConfig {
  API?: {};
}

const Auth = (props: Props) => {
  const config = useConfig();
  const amplifyConfig: AmplifyConfig = config.Amplify;
  const [username, setUsername] =
    useState<string | undefined | null>(undefined);

  const queryClient = useQueryClient();
  useEffect(() => {
    Amplify.configure({
      ...amplifyConfig,
      API: {
        ...amplifyConfig?.API,
        graphql_headers: async () => {
          const session = await AmplifyAuth.currentSession();
          return {
            Authorization: session.getIdToken().getJwtToken(),
          };
        },
      },
    });
    AmplifyAuth.currentAuthenticatedUser()
      // .then((d) => sleep(500).then(() => d))
      .then((data) => setUsername(data.username))
      .catch((error) => setUsername(null));
  }, [amplifyConfig]);

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        // case 'cognitoHostedUI':
        case "signIn":
          console.log(`${Date()} signed in`, data);
          setUsername(data.username);
          break;
        case "signOut":
          console.log(`${Date()} signed out`);
          setUsername(null);
          queryClient.clear();
          break;
        // case 'cognitoHostedUI_failure':
        case "signIn_failure":
          console.log(`${Date()} Sign in failure`, data);
          break;
        default:
          console.log(`${Date()} unhandled hub event`, event, data);
          break;
      }
    });
    return () => {
      Hub.remove("auth", ({ channel }) => console.log("removed", channel));
    };
  }, [queryClient]);

  if (username === undefined) return <PlsHold message="wait for auth" />;

  return (
    <AuthContext.Provider value={{ username }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext };

export default Auth;
// function MaybeLogin(props: Props) {
//   const user = useState(undefined);
//   const { authStatus, route, error, isPending } = useAuthenticator((context) => [context.authStatus, context.route, context.error, context.isPending]);
//   console.log("authStatus", authStatus);
//   console.log("route", route);
//   console.log("error", error);
//   console.log("isPending", isPending);
//   return authStatus === "configuring" ? (
//     <PlsHold message="waiting for auth" />
//   ) : authStatus === "authenticated" ? (
//     <>{props.children}</>
//   ) : (
//     <p>log in yo</p>
//   );
// }

// export const _useUsername = () =>
//   useAuthenticator((context) => [context.user])?.user?.username;

// export default function Auth(props: Props) {
//   const config = useConfig();
//   useEffect(() => {
//     Amplify.configure(config.Amplify);
//     AmplifyAuth.currentAuthenticatedUser().then((u) => console.log("user", u)).catch(e => console.log("error", e));
//     console.log("lets use", config);
//   }, [config])

//   return (
//     <AmplifyAuthenticator.Provider>
//       <MaybeLogin {...props} />
//     </AmplifyAuthenticator.Provider>
//   );
// }
