import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GraphQLResult } from "@aws-amplify/api";
import { useMutation, useQueryClient } from "react-query";

import useData from "../../../apis/organisations";
import { Organisation, PlanInterval } from "../../../generated/graphql";
import Button from "../../Button";

import { Form, FormItem, FormGroup, FormButton, FormConfirmButton } from "../../Form";
import ButtonGroup from "../../ButtonGroup";
import { Link } from "components";

const Item = (props: Organisation) => {
  return !!props ? <Edit {...props} /> : <Create />;
}

const Edit = (data: Organisation) => {
  return (
    <>
      <Create item={data} />
      <hr />
      <section>
        <header>
          <h2 id="billing">billing</h2>
        </header>
        {!data?.billing?.active ?
          (
            <Subscriptions intervals={data.billing.plans[0].intervals} />
          ) : (
            <Button<typeof Link> as={Link} to="billing">manage billing</Button>
          )
        }
      </section>
    </>
  );
};

const SubscriptionCard = ({title, features, footer}: {title: React.ReactNode, features: Array<React.ReactNode>, footer: React.ReactNode}) => (
  <div className="terminal-card" style={{flex: 1, display: "flex", flexDirection: "column"}}>
    <header>{title}</header>
    <div style={{flex: 1}}>
      <ul>
        {features.map(feature => <li>{feature}</li>)}
      </ul>
    </div>
    {footer}
  </div>

)

const Subscriptions = ({intervals}: {intervals: PlanInterval[] }) => (
  <section style={{display: "flex"}}>
    <SubscriptionCard
      title="Free Domains"
      features={[
        "rotating domains",
        "automatic HTTPS/TLS certificates",
        "automatic HTTPS termination with HTTP routing",
        "limited bandwidth",
      ]}
      footer={<Button<"a"> href="http://localhost.run/docs/forever-free" target="lhr-docs" as="a" className="btn-block">use free</Button>}
    />

    <SubscriptionCard
      title="Custom Domains"
      features={[
        "stable domain names",
        "bring your own domains or use provided domain name",
        "automatic HTTPS/TLS certificates",
        "automatic HTTPS termination with TLS routing",
        "optional TLS passthru",
    ]}
      footer={
        <>
          <Link to="checkout/month" relative="path">
            ${intervals[0].price / 100} per custom domain / month billed monthly
          </Link>
          <Button<typeof Link> as={Link} to="checkout/year" relative="path">
            ${Math.ceil(intervals[1].price / 12) / 100} per custom domain / month billed anually
          </Button>
        </>
      }
    />
  </section>
)

const Create = ({
  item,
}: {
  item?: Omit<Organisation, "id"> & { id?: string };
}) => {
  const navigate = useNavigate();
  const api = useData();
  const queryClient = useQueryClient();

  const form = useForm();
  const { handleSubmit, setError } = form;

  const createMutation = useMutation(api.create, {
    onSuccess: () => {
      queryClient.removeQueries(["organisations"]);
      navigate(-1);
    },
    onError: (error: GraphQLResult) =>
      setError("name", {
        type: "server",
        message: error.errors?.[0].message,
      })
  });

  const removeMutation = useMutation(api.remove, {
    onSuccess: () => {
      queryClient.removeQueries(["organisations"]);
      navigate(-1);
    },
    onError: (error: GraphQLResult) =>
      setError("name", {
        type: "server",
        message: error.errors?.[0].message,
      })
  });

  const loading = createMutation.isLoading || removeMutation.isLoading;

  const onSubmit = handleSubmit(({ name }) => {
    createMutation.mutate({ input: { name } });
  });


  return (
    <>
      <Form loading={loading} form={form} onSubmit={onSubmit}>
        <fieldset>
          <FormGroup hidden={!item?.id}>
            <FormItem
              id="id"
              readOnly
              label="ID"
              required
              disabled
              defaultValue={item?.id}
              type="text"
            />
          </FormGroup>
          <FormGroup>
            <FormItem
              id="name"
              readOnly={!!item?.id}
              required
              type="text"
              autoFocus
              defaultValue={item?.name}
              placeholder="name"
            />
          </FormGroup>

          <ButtonGroup>
            {!item?.id && (
              <FormButton
                primary
                type="submit"
                name="submit"
                id="submit"
                hidden={!!item?.id}
                disabled={!!item?.id}
              >
                submit
              </FormButton>
            )}
            {item?.id && false && (
              <FormConfirmButton
                error
                type="button"
                name="delete"
                onClick={() =>
                  !!item?.id && removeMutation.mutate({ input: { id: item.id } })
                }
              >
                delete
              </FormConfirmButton>
            )}
          </ButtonGroup>
        </fieldset>
      </Form>
    </>
  );
};

export {
  Create,
  Edit,
};

export default Item;
