export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** aws email */
  AWSEmail: any;
  AWSURL: any;
};

export enum BillingIntervalEnum {
  Month = 'month',
  Year = 'year'
}

export type CreateDomainInput = {
  name: Scalars['String'];
  organisationId: Scalars['ID'];
};

export type CreateDomainPayload = {
  __typename?: 'CreateDomainPayload';
  domain: Domain;
};

export type CreateOrganisationInput = {
  name: Scalars['String'];
};

export type CreateOrganisationPayload = {
  __typename?: 'CreateOrganisationPayload';
  organisation: Organisation;
};

export type CreateSshKeyPairInput = {
  description?: InputMaybe<Scalars['String']>;
  publicKey: Scalars['String'];
};

export type CreateSshKeyPairPayload = {
  __typename?: 'CreateSshKeyPairPayload';
  sshKeyPair: SshKeyPair;
};

export type DeleteDomainInput = {
  id: Scalars['ID'];
};

export type DeleteDomainPayload = {
  __typename?: 'DeleteDomainPayload';
  domainId: Scalars['ID'];
};

export type DeleteOrganisationInput = {
  id: Scalars['ID'];
};

export type DeleteOrganisationPayload = {
  __typename?: 'DeleteOrganisationPayload';
  organisationId: Scalars['ID'];
};

export type DeleteSshKeyPairInput = {
  id: Scalars['ID'];
};

export type DeleteSshKeyPairPayload = {
  __typename?: 'DeleteSshKeyPairPayload';
  sshKeyPairId: Scalars['ID'];
};

export type Domain = Node & {
  __typename?: 'Domain';
  dns: DomainDns;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DomainConnection = {
  __typename?: 'DomainConnection';
  edges: Array<DomainEdge>;
  pageInfo: PageInfo;
  total: Scalars['Int'];
};

export type DomainDns = {
  __typename?: 'DomainDns';
  alias?: Maybe<DomainDnsAlias>;
  txt?: Maybe<DomainDnsTxt>;
};

export type DomainDnsAlias = {
  __typename?: 'DomainDnsAlias';
  active: Scalars['Boolean'];
  target: Scalars['String'];
};

export type DomainDnsTxt = {
  __typename?: 'DomainDnsTxt';
  active: Scalars['Boolean'];
  target: Scalars['String'];
};

export type DomainEdge = {
  __typename?: 'DomainEdge';
  cursor?: Maybe<Scalars['String']>;
  node: Domain;
};

export type Mutation = {
  __typename?: 'Mutation';
  createDomain: CreateDomainPayload;
  createOrganisation: CreateOrganisationPayload;
  createSshKeyPair: CreateSshKeyPairPayload;
  deleteDomain: DeleteDomainPayload;
  deleteOrganisation: DeleteOrganisationPayload;
  deleteSshKeyPair: DeleteSshKeyPairPayload;
  updateOrganisation: UpdateOrganisationPayload;
};


export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};


export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput;
};


export type MutationCreateSshKeyPairArgs = {
  input: CreateSshKeyPairInput;
};


export type MutationDeleteDomainArgs = {
  input: DeleteDomainInput;
};


export type MutationDeleteOrganisationArgs = {
  input: DeleteOrganisationInput;
};


export type MutationDeleteSshKeyPairArgs = {
  input: DeleteSshKeyPairInput;
};


export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput;
};

export type Node = {
  id: Scalars['ID'];
};

export type Organisation = Node & {
  __typename?: 'Organisation';
  billing: OrganisationBilling;
  domains: DomainConnection;
  id: Scalars['ID'];
  name: Scalars['String'];
};


export type OrganisationDomainsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type OrganisationBilling = {
  __typename?: 'OrganisationBilling';
  active: Scalars['Boolean'];
  balance?: Maybe<Scalars['Float']>;
  billingPortalSessionUrl: Scalars['AWSURL'];
  billingUrl?: Maybe<Scalars['AWSURL']>;
  card?: Maybe<StripePaymentMethodCard>;
  checkoutUrl: Scalars['AWSURL'];
  needsAttention: Scalars['Boolean'];
  nextBillAmount?: Maybe<Scalars['Float']>;
  paymentIntent?: Maybe<PaymentIntent>;
  plans: Array<Plans>;
  price?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  setupIntentClientSecret: Scalars['String'];
};


export type OrganisationBillingBillingPortalSessionUrlArgs = {
  returnUrl?: InputMaybe<Scalars['AWSURL']>;
};


export type OrganisationBillingBillingUrlArgs = {
  returnUrl?: InputMaybe<Scalars['AWSURL']>;
};


export type OrganisationBillingCheckoutUrlArgs = {
  interval?: InputMaybe<BillingIntervalEnum>;
  returnUrl?: InputMaybe<Scalars['AWSURL']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  client_secret?: Maybe<Scalars['String']>;
  status?: Maybe<PaymentIntentStatusEnum>;
};

export enum PaymentIntentStatusEnum {
  RequiresAction = 'requires_action',
  RequiresPaymentMethod = 'requires_payment_method',
  Succeeded = 'succeeded'
}

export type PlanInterval = {
  __typename?: 'PlanInterval';
  active: Scalars['Boolean'];
  interval: PlanIntervalPeriodEnum;
  price: Scalars['Int'];
};

export enum PlanIntervalPeriodEnum {
  Month = 'month',
  Year = 'year'
}

export type Plans = {
  __typename?: 'Plans';
  features: Array<Scalars['String']>;
  intervals: Array<PlanInterval>;
  title: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  domain: Domain;
  organisation: Organisation;
  sshKeyPair: SshKeyPair;
  user: User;
};


export type QueryDomainArgs = {
  id: Scalars['ID'];
};


export type QueryOrganisationArgs = {
  id: Scalars['ID'];
};


export type QuerySshKeyPairArgs = {
  id: Scalars['ID'];
};

export type SshKeyPair = Node & {
  __typename?: 'SshKeyPair';
  description?: Maybe<Scalars['String']>;
  fingerprint?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  privateKey?: Maybe<Scalars['String']>;
  publicKey: Scalars['String'];
};

export type StripePaymentMethodCard = {
  __typename?: 'StripePaymentMethodCard';
  brand: Scalars['String'];
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  last4: Scalars['String'];
};

export type UpdateOrganisationInput = {
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganisationPayload = {
  __typename?: 'UpdateOrganisationPayload';
  organisation: Organisation;
};

export type User = Node & {
  __typename?: 'User';
  email: Scalars['AWSEmail'];
  id: Scalars['ID'];
  organisations: UserOrganisationConnection;
  sshKeyPairs: UserSshKeyPairConnection;
};


export type UserOrganisationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};


export type UserSshKeyPairsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type UserOrganisationConnection = {
  __typename?: 'UserOrganisationConnection';
  edges: Array<UserOrganisationEdge>;
  pageInfo: PageInfo;
};

export type UserOrganisationEdge = {
  __typename?: 'UserOrganisationEdge';
  cursor?: Maybe<Scalars['String']>;
  isOwner: Scalars['Boolean'];
  node: Organisation;
};

export type UserSshKeyPairConnection = {
  __typename?: 'UserSshKeyPairConnection';
  edges: Array<UserSshKeyPairEdge>;
  pageInfo: PageInfo;
};

export type UserSshKeyPairEdge = {
  __typename?: 'UserSshKeyPairEdge';
  cursor?: Maybe<Scalars['String']>;
  node: SshKeyPair;
};

export type DomainsQueryVariables = Exact<{
  orgId: Scalars['ID'];
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
}>;


export type DomainsQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', domains: { __typename?: 'DomainConnection', total: number, edges: Array<{ __typename?: 'DomainEdge', node: { __typename?: 'Domain', id: string, name: string, dns: { __typename?: 'DomainDns', txt?: { __typename?: 'DomainDnsTxt', active: boolean } | null, alias?: { __typename?: 'DomainDnsAlias', active: boolean } | null } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } } };

export type DomainQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DomainQuery = { __typename?: 'Query', domain: { __typename?: 'Domain', id: string, name: string, dns: { __typename?: 'DomainDns', txt?: { __typename?: 'DomainDnsTxt', active: boolean, target: string } | null, alias?: { __typename?: 'DomainDnsAlias', active: boolean, target: string } | null } } };

export type CreateDomainMutationVariables = Exact<{
  input: CreateDomainInput;
}>;


export type CreateDomainMutation = { __typename?: 'Mutation', createDomain: { __typename?: 'CreateDomainPayload', domain: { __typename?: 'Domain', id: string } } };

export type DeleteDomainMutationVariables = Exact<{
  input: DeleteDomainInput;
}>;


export type DeleteDomainMutation = { __typename?: 'Mutation', deleteDomain: { __typename?: 'DeleteDomainPayload', domainId: string } };

export type SshKeyPairsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
}>;


export type SshKeyPairsQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: any, sshKeyPairs: { __typename?: 'UserSshKeyPairConnection', edges: Array<{ __typename?: 'UserSshKeyPairEdge', node: { __typename?: 'SshKeyPair', id: string, publicKey: string, fingerprint?: string | null, description?: string | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } } };

export type SshKeyPairQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SshKeyPairQuery = { __typename?: 'Query', sshKeyPair: { __typename?: 'SshKeyPair', id: string, publicKey: string, fingerprint?: string | null, description?: string | null } };

export type CreateSshKeyPairMutationVariables = Exact<{
  input: CreateSshKeyPairInput;
}>;


export type CreateSshKeyPairMutation = { __typename?: 'Mutation', createSshKeyPair: { __typename?: 'CreateSshKeyPairPayload', sshKeyPair: { __typename?: 'SshKeyPair', id: string } } };

export type DeleteSshKeyPairMutationVariables = Exact<{
  input: DeleteSshKeyPairInput;
}>;


export type DeleteSshKeyPairMutation = { __typename?: 'Mutation', deleteSshKeyPair: { __typename?: 'DeleteSshKeyPairPayload', sshKeyPairId: string } };

export type OrganisationBillingBillingUrlQueryVariables = Exact<{
  id: Scalars['ID'];
  returnUrl?: InputMaybe<Scalars['AWSURL']>;
}>;


export type OrganisationBillingBillingUrlQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', billing: { __typename?: 'OrganisationBilling', billingPortalSessionUrl: any } } };

export type OrganisationBillingCheckoutUrlQueryVariables = Exact<{
  id: Scalars['ID'];
  interval?: InputMaybe<BillingIntervalEnum>;
  returnUrl?: InputMaybe<Scalars['AWSURL']>;
}>;


export type OrganisationBillingCheckoutUrlQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', billing: { __typename?: 'OrganisationBilling', checkoutUrl: any } } };

export type OrganisationsQueryVariables = Exact<{
  after?: InputMaybe<Scalars['String']>;
  first: Scalars['Int'];
}>;


export type OrganisationsQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: any, organisations: { __typename?: 'UserOrganisationConnection', edges: Array<{ __typename?: 'UserOrganisationEdge', node: { __typename?: 'Organisation', id: string, name: string, billing: { __typename?: 'OrganisationBilling', active: boolean } } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null } } } };

export type OrganisationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganisationQuery = { __typename?: 'Query', organisation: { __typename?: 'Organisation', id: string, name: string, billing: { __typename?: 'OrganisationBilling', active: boolean, plans: Array<{ __typename?: 'Plans', title: string, features: Array<string>, intervals: Array<{ __typename?: 'PlanInterval', interval: PlanIntervalPeriodEnum, price: number, active: boolean }> }> } } };

export type CreateOrganisationMutationVariables = Exact<{
  input: CreateOrganisationInput;
}>;


export type CreateOrganisationMutation = { __typename?: 'Mutation', createOrganisation: { __typename?: 'CreateOrganisationPayload', organisation: { __typename?: 'Organisation', id: string } } };

export type DeleteOrganisationMutationVariables = Exact<{
  input: DeleteOrganisationInput;
}>;


export type DeleteOrganisationMutation = { __typename?: 'Mutation', deleteOrganisation: { __typename?: 'DeleteOrganisationPayload', organisationId: string } };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, email: any } };
