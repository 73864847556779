import React from "react";
import { Link as RealLink, useSearchParams } from "react-router-dom";

const Link = ({
  to,
  ...props
}: Omit<React.ComponentProps<typeof RealLink>, "to"> & {
  to: string | { pathname: string; search?: URLSearchParams };
}) => {
  const [searchParams] = useSearchParams();

  if (typeof to === "string") {
    return (
      <RealLink
        to={{
          pathname: to,
          search: searchParams.toString(),
        }}
        {...props}
      />
    );
  } else {
    const combinedSearchParams = new URLSearchParams(
      Object.fromEntries([
        ...Array.from(searchParams.entries()),
        ...Array.from(!!to.search ? to.search.entries() : []),
      ])
    );
    return (
      <RealLink
        to={{
          pathname: to.pathname,
          search: combinedSearchParams.toString(),
        }}
        {...props}
      />
    );
  }
};

export default Link;
