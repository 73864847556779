import { ReactNode } from "react";

const Header = ({
  left,
  right = [],
}: {
  left?: ReactNode;
  right?: Array<ReactNode> | ReactNode;
}) => {
  const realisedRight = (Array.isArray(right) ? right : [right]).map((n, i) => (
    <li key={i}>{n}</li>
  ));
  return (
    <div className="terminal-nav">
      <div style={{ whiteSpace: "nowrap" }}>{left}</div>
      <nav className="terminal-menu">
        <ul>{realisedRight}</ul>
      </nav>
    </div>
  );
};

export default Header;
